import React, { Fragment } from "react";
import "../assets/sass/style.scss";

import Seo from "../blocks/seo/Seo";
import Header from "../blocks/header/Header";
import Footer from "../blocks/footer/Footer";

import PageTitleGeneric from "../blocks/page-title/PageTitleGeneric";
import AboutContent from "../blocks/about/AboutContent";
import WhatIDo from "../blocks/about/WhatIDo";
import BlockContact from "../blocks/contact/BlockContact";

const About = () => {
  return (
    <Fragment>
      <Seo
        title="About"
        keywords="about, info, detail, bio"
        bodyAttributes={{
          class: "page bg-fixed bg-line",
        }}
      />

      <Header />

      <main id="main" className="site-main">
        <PageTitleGeneric title="About" />

        <section id="page-content" className="spacer p-top-xl">
          <div className="wrapper">
            <AboutContent />

            <WhatIDo />
            <BlockContact goalId="QFUPJMU2" />
          </div>
        </section>
      </main>

      <Footer />
    </Fragment>
  );
};

export default About;
